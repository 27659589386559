import React, {Component} from 'react'
import FlexView from 'react-flexview';
import SharedStyles from './SharedStyles.js'
import Backdrop from './Backdrop';
import Radium from 'radium';

// const items = ['Word Lists', 'Unscramble', 'Word Solver', 'Blog']

const styles = {
  items : { 
    // color: '#CECECE',
    // color: 'white',
    padding: '0.8rem 0.8rem',
    textDecoration: 'none',
    ':hover': {
      color: SharedStyles.colors.blue,
    },
  },
  drawer: {
    backgroundColor: 'white',
    height: '100%',
    boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top:0,
    left:0,
    width: '60%',
    maxWidth: '400px',
    zIndex: 2,
    transform: 'translateX(-100%)',
    transition: 'transform 0.3s ease-out',
    paddingTop: '70px'
  },
  dropdownItem: {
    padding:'5px',
    // backgroundColor:'red',
    color: SharedStyles.colors.grey,
    textDecoration: 'none',
    ':hover': {
      color: SharedStyles.colors.blue
      // backgroundColor: '#3A94CF'
    }
  }
}

class sideDrawer extends Component {

  state={
    dropdown: []
  }

  toggleDropdown = (i) => {
    console.log('event', i)
    var newDropdown = this.state.dropdown
    newDropdown[i] = !this.state.dropdown[i]
    this.setState({dropdown: newDropdown})
  }

  createDropdownItems = (items, links) => {
    return items.map((item, i) => (<a key={i + item} href={links[i]} style={styles.dropdownItem}>{item}</a>))
  }

  renderSidebar = (items, width) => {
    // console.log('content', 
    const ren = items.map((item,i) => {
      if (item.type === 'Link') {
        return (
          <FlexView key={i}>
            <a style={{...SharedStyles.h4, ...styles.items}} key={i} href={item.link}>{item.heading}</a>
          </FlexView>
        )          
      } else if (item.type === 'Dropdown') {
        const arrow = this.state.dropdown[i] ? ' ↑' : ' ↓'
        return (
          <FlexView key={i} column onClick={() => this.toggleDropdown(i)}>
            <a style={{...SharedStyles.h4, ...styles.items}} key={i} href={item.link}>{item.heading + arrow}</a>
            {this.state.dropdown[i] ? <FlexView column style={{...SharedStyles.h5, ...styles.items}}>{this.createDropdownItems(item.items.split(', '), item.links.split(', '))}</FlexView> : null}
          </FlexView>
        )          
      } else {
        return null
      }
    })
    return ren
  }

  render() {
    const translateX = this.props.open ? 'translateX(0)' : 'translateX(-100%)'
    // let headings = []
    // let links = []
    // if (this.props.content) {
    //   const SideDrawer = Object.keys(this.props.content).map(i => this.props.content[i]).filter(item => item.type === 'SideDrawer')
    //   headings = SideDrawer[0].headings.split(', ')
    //   links = SideDrawer[0].links.split(', ')
    //   // console.log('toolbar Object.keys(pageContent)', Object.keys(this.props.content).map(i => this.props.content[i]))
    // }
    // console.log('SideDrawer', SideDrawer)
    let items = []
    if (this.props.content) {
      items = Object.keys(this.props.content).map(i => this.props.content[i])
      // console.log('toolbar Object.keys(pageContent)', Object.keys(this.props.content).map(i => this.props.content[i]))
    }

    // {headings.map((item, i) => (<FlexView key={i}><a style={{...SharedStyles.h4, ...styles.items}} key={i} href={links[i]}>{item}</a></FlexView>))}

    return (
      <FlexView>
        <FlexView hAlignContent='center' style={{...styles.drawer, transform: translateX}} column>
          {this.renderSidebar(items)}
        </FlexView>
        <Backdrop open={this.props.open} toggleDrawer={this.props.toggleDrawer} />
      </FlexView>

    )
  }

}

export default Radium(sideDrawer)