import React, {Component} from 'react'
import FlexView from 'react-flexview';
import Navigation from './components/Navigation';
import Searchbar from './components/Searchbar';

//Content

import ParagraphBlock from './components/ParagraphBlock';
import paragraphContent from './content/paragraph.json'
import SummaryBlocks from './components/SummaryBlocks';
import blocksContent from './content/blocks.json'
import HighlightBlock from './components/HighlightBlock';
import highlightContent from './content/paragraph.json'
import WordLists from './components/WordLists';
import wordlistContent from './content/wordLists.json'
import Footer from './components/Footer';
import footerContent from './content/footer.json'
import Links from './components/Links';
import linksContent from './content/links.json'


export default class Home extends Component {
  state={
    search: {
      main: '',
      startsWith: '',
      contains: '',
      endsWith: ''
    }
  }

  handleSearchSubmit = (event) => {
    event.preventDefault();
    this.props.history.push(`/unscramble/${this.state.search}`)
  }

  handleSearchChange = (event, type) => {
    let searchState = this.state.search
    searchState[type] = event.target.value.slice(0,15)
    this.setState({search:searchState})
  }

  render () {
    // const unscrmbled = unscramble('poletad', 't', 'o', 'd')
    console.log('match', this.props.match.params)
    return (
      <FlexView name='parent' column={true} style={{height: '100%'}}>
        <Navigation screenSize={this.props.screenSize}/>
        <Searchbar
          heading={'Word Finder & Unscrambler'}
          subtitle={'Enter up to 15 letters into the word unscrambler'}
          highlightedWord={'Unscrambler'}
          onSubmit={this.handleSearchSubmit}
          search={this.state.search}
          onChange={this.handleSearchChange}
          screenSize={this.props.screenSize}/>
        <ParagraphBlock
          heading={paragraphContent.content.heading}
          body={paragraphContent.content.body}
          screenSize={this.props.screenSize}
          />
        <SummaryBlocks
          content={blocksContent.content}
          screenSize={this.props.screenSize}
          />
        <HighlightBlock
          heading={highlightContent.content.heading}
          body={highlightContent.content.body}
          screenSize={this.props.screenSize}
          />
        <WordLists
          content={wordlistContent.content}
          screenSize={this.props.screenSize}
          />
        <Links
          heading={linksContent.content.heading}
          links={linksContent.content.links}
          columns={4}
          screenSize={this.props.screenSize}
          />
        <Footer
          content={footerContent.content}
          screenSize={this.props.screenSize}
          />
      </FlexView>
    );
  }
}