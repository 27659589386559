import React from 'react'
import FlexView from 'react-flexview'
import SharedStyles from './SharedStyles.js'

const styles = {
  block: {
    marginTop: '0px',
    marginLeft:'6vw',
    marginRight:'6vw',
    width: SharedStyles.gContent.maxWidth,
    // textAlign: 'left',
    // paddingLeft: '5vw',
    // paddingRight: '5vw',
    // paddingTop: '1vw',
    // paddingBottom: '1vw',
    // backgroundColor: 'red'
  }
};

const Image = props => {

  return (
    <FlexView style={{...styles.block, width: '80vw'}} column vAlignContent='center' hAlignContent={'center'}>
      <img style={{maxWidth:SharedStyles.gContent.maxWidth, width: '80vw'}} src={require('./../images/' + props.name)} alt="Smiley face"/>
    </FlexView>
  )
}

export default Image;
