import React, {Component} from 'react'
import FlexView from 'react-flexview';
import * as firebase from "firebase/app"
import 'firebase/functions'
import Tiles from './Tiles'
import SharedStyles from './SharedStyles.js'
import Radium from 'radium';



class SearchResults extends Component {

  // state={
  //   words: this.props.words
  // }


  componentDidMount = async () => {
    console.log('this.props',this.props)
    // const {word, startsWith, contains, endsWith} = this.props.search
    // if (word) {
    //   // console.log('unscrambling', word.slice(0,15))
    //   this.unscrambleWord(word.slice(0,15), startsWith, contains, endsWith)
    // }
  }


  buildWordCount = () => {
    return (
      <FlexView hAlignContent='right' style={{...SharedStyles.h4, marginRight:'9vw'}}>
        <span style={{fontWeight:SharedStyles.h2.fontWeight}}>{this.props.resultsCount + '\xa0'}</span> 
          {this.props.baseWord !== '' ?  <text>words with letters </text> :  <text>words found</text>}
          
          {this.props.baseWord !== '' ? <span style={{fontWeight:SharedStyles.h2.fontWeight}}> {'\xa0' + this.props.baseWord}</span> : null}
      </FlexView>
    )
  }

  genNoWordsFound = () => {
    return (
      <FlexView
        vAlignContent='center'
        hAlignContent='center'
        style={{...SharedStyles.h3, marginBottom:'150px', marginTop:'100px', color:SharedStyles.colors.red}}>
        Sorry, there are no words matching your criteria. Try reducing your filters.
      </FlexView>
    )
  }

  buttonClickHandler = () => {

  }

  render() {
    console.log('this.props',this.props)
    return (
      <FlexView name='parent' column={true} style={{height: '100%'}}>
        {this.props.loading ? <FlexView style={{...SharedStyles.h2, color: SharedStyles.colors.blue}} vAlignContent='center' hAlignContent='center'>Loading . . .</FlexView> : null}
        {!this.props.loading ? this.buildWordCount() : null}
        {!this.props.loading && this.props.words.length < 1 ? this.genNoWordsFound() : null }
        <FlexView
          style={{marginLeft:'7vw',marginRight:'7vw', backgroundColor:'transparent'}}
          hAlignContent='center'
          vAlignContent='center'>
          <Tiles words={this.props.words}/>
          
        </FlexView>
        {this.props.showButton ?
          <FlexView hAlignContent='center'>
            <button onClick={this.props.reSearch} style={{...SharedStyles.button, width: '500px'}}>Load All Results</button>
          </FlexView> : null }
        
      </FlexView>
    )
  }
}

export default Radium(SearchResults)