import React, {Component} from 'react'
import FlexView from 'react-flexview';
import { ReactComponent as Icon} from './../images/menu.svg';
import SharedStyles from './SharedStyles.js'
import Radium from 'radium';

const styles = {
  toolbar: {
    // position:'fixed',
    top:0,
    left:0,
    right: 0,
    backgroundColor: 'white',
    height: '56px',
    width:'100vw',
    zIndex: 0
  },
  logo : {
    color:'#4B93E8',
    textDecoration: 'none',
    fontSize: '1.5rem',
    fontFamily: 'Helvetica Neue',
    fontWeight: 'bold',
    paddingLeft: '12px'
  },
  list : {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    textDecoration: 'none'
  },
  items : {
    // color: '#CECECE',
    padding: '0 0.8rem',
    textDecoration: 'none',
    ':hover': {
      color: SharedStyles.colors.blue
      // backgroundColor: '#3A94CF'
    },
    marginBottom:'24px',
    marginTop:'24px',
    // fontFamily: 'Helvetica Neue',

  },
  spacer : {
    // backgroundColor:'green'
  },
  menu : {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 20,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
  },
  dropdown: {
    position:'absolute',
    // width:'180px',
    // height: '200px',
    backgroundColor: 'white',
    top: '60px',
    // left: '40px',
    boxShadow: '0px 2px 7px rgba(0,0,0,0.05)',
    border: '2px solid transparent',
    borderRadius: '4px',
    // backgroundColor:'blue'
    
  },
  dropdownItem: {
    padding:'10px',
    // backgroundColor:'red',
    color: SharedStyles.colors.black,
    textAlign: 'left',
    textDecoration: 'none',
    ':hover': {
      color: SharedStyles.colors.blue
      // backgroundColor: '#3A94CF'
    },
  }
};

// const dropdownItems = ['Words With, Words That Start With', 'Words Ending In', 'Words By Length']


class Toolbar extends Component {
  state={
    dropdown: []
  }

  toggleDropdown = (i, bol) => {
    console.log('event', i)
    var newDropdown = this.state.dropdown
    newDropdown[i] = bol
    this.setState({dropdown: newDropdown})
  }

  createDropdownItems = (items, links) => {
    return items.map((item, i) => (<a key={i + 'drop'} href={links[i]} style={styles.dropdownItem}>{item}</a>))
  }

  renderToolbar = (items, width) => {

    // console.log('content', )
    if (width > 768) {
      return (items.map((item,i) => {
        if (item.type === 'Link') {
          return (
            <FlexView key={i} onMouseEnter={() => this.toggleDropdown(i, true)} onMouseLeave={() => this.toggleDropdown(i, false)}>
              <a style={{...SharedStyles.h4, ...styles.items}} key={i} href={item.link}>{item.heading}</a>
            </FlexView>
          )          
        } else if (item.type === 'Dropdown') {
          return (
            <FlexView key={i} onMouseEnter={() => this.toggleDropdown(i, true)} onMouseLeave={() => this.toggleDropdown(i, false)}>
              <a style={{...SharedStyles.h4, ...styles.items}} key={i} href={item.link}>{item.heading}</a>
              {this.state.dropdown[i] ? <FlexView column style={styles.dropdown}>{this.createDropdownItems(item.items.split(', '), item.links.split(', '))}</FlexView> : null}
            </FlexView>
          )          
        }
        return (
          <FlexView key={i}
            onMouseEnter={() => this.toggleDropdown(i, true)}
            onMouseLeave={() => this.toggleDropdown(i, false)}
            >
            <a style={styles.items}
              key={i}
              href={item.link}>{item.heading}</a>
            {/* {this.state.dropdown[i] &&  dropdownItems[item] ? <FlexView column style={styles.dropdown}>{this.createDropdownItems(dropdownItems[item])}</FlexView> : null} */}
          </FlexView>
        )
      }))
    } else {
      return null
    }
  }
  
  render () {
    console.log('toolbar content', this.props.content)
    // let items = [{heading:'Word Lists', link: 'blog', type:'link'}, {heading:'Unscramble', link: 'blog', type:'link'}]
    let items = []
    if (this.props.content) {
      items = Object.keys(this.props.content).map(i => this.props.content[i])
      // console.log('toolbar Object.keys(pageContent)', Object.keys(this.props.content).map(i => this.props.content[i]))
    }
    
    return (
      <FlexView style={styles.toolbar} vAlignContent='center' id='header'>
        <FlexView></FlexView>
        <FlexView><a style={styles.logo} href="/">WORDRATER</a></FlexView>
        <FlexView grow style={styles.spacer}/>
        <FlexView style={{marginRight:'20px'}}>
          {this.renderToolbar(items, this.props.screenSize.width)}
        </FlexView>
        { this.props.screenSize.width < 768 ?
          <button style={styles.menu} onClick={this.props.toggleDrawer}>
          <Icon></Icon>
          </button> : null
        }

      </FlexView>
    )
  }
}

export default Radium(Toolbar)
