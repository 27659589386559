import React from 'react'
import FlexView from 'react-flexview';

let scrabbleScores = { 'a': 1, 'b': 3, 'c': 3, 'd': 2, 'e': 1, 'f': 4, 'g': 2, 'h': 4, 'i': 1, 'j': 8, 'k': 5, 'l': 1, 'm': 3, 'n': 1, 'o': 1, 'p': 3, 'q': 10, 'r': 1, 's': 1, 't': 1, 'u': 1, 'v': 4, 'w': 4, 'x': 8, 'y': 4, 'z': 10 };

const colors = {
  green: '#9ED6A4',
  blue: '#6FC3F3',
  purple: '#D893E6',
  yellow: '#F5CF80',
  red: '#FEBBA6',
  grey: '#4B5567'
}
const colorIndex = ['green', 'blue', 'purple', 'yellow', 'red']

export default function Tiles({words}) {

  const styles = {
    parent: {
    // justifyContent: 'space-around',
    // alignItems: 'center',
      // backgroundColor: 'red',
      // overflow: 'hidden',
      // 'flex-basis': 'auto',
      // 'flex-grow': 0,
      // 'flex-shrink': 1
      // width: '50vw',
      // height: 100,
    },
    section: {
      // justifyContent: 'space-around',
      // alignItems: 'center',
      margin: '1vw',
    },
    sectionHeading: {
      // fontSize: '24px',
      border: '1px solid #FFC64E',
      borderRadius: 3,
      margin: '4px',
      width: '50px',
      height: '50px'
    },
    tile: {
      border: '1px solid #FFC64E',
      // backgroundColor: '#FFC64E',
      padding: '0px',
      paddingLeft: '6px',
      paddingTop: '1px',
      paddingRight: '5px',
      margin: '4px',
      borderRadius: 3,

    },
    word: {
      // flex: 1,              /* 2 */
      // border: '1px solid transparent',
      // backgroundColor: 'blue',
      padding: '0',
      margin: '0',
      color: '#041847',
      fontSize: 'medium',
      lineHeight: '180%',
      textDecoration: 'none',
      // borderRadius: 3,
    },
    points: {
      fontSize:'11px',
      fontWeight: '250',
      marginLeft: '2px'
      // backgroundColor:'red',
    }
  };

  function getScrabblePoints(chars) {
    let charArray = chars.split('');
    var score = 0;
    // console.log('in getScrabblePoints');
  
    for (var i = 0; i < charArray.length; i++) {
      // console.log(charArray[i]);
      score += scrabbleScores[charArray[i]];
      // console.log('score = ' + score);
    }
    return score;
  }

  function createTile(word, color) {

    return (
      <FlexView onClick={() => console.log(word)} key={word} vAlignContent='center' style={{...styles.tile, borderColor:color}}>
        {/* <h4 style={styles.word}>{word}<sub style={styles.points}>{getScrabblePoints(word)}</sub></h4> */}
        <a style={styles.word} href={'/dictionary/' + word}>{word}<sub style={styles.points}>{getScrabblePoints(word)}</sub></a>
      </FlexView>
      
    )
  }

  function createSection(words, i) {
    const color = colors[colorIndex[i]]
    return (
      <FlexView key={'section' + i} vAlignContent='top' style={styles.section}>
        <FlexView column basis={50} vAlignContent='center' hAlignContent='center'>
          <FlexView basis={50} vAlignContent='center' hAlignContent='center' style={{...styles.sectionHeading, borderColor:color}}>
            <h1 style={{backgroundColor: 'transparent',padding:0, margin:0, textAlign: 'center', height: '40px'}}>{words[0].length}</h1>
          </FlexView>
          <FlexView style={{color: colors.grey, fontSize: '12px'}}>LETTER</FlexView>
          <FlexView style={{color: colors.grey, fontSize: '12px'}}>WORDS</FlexView>
        </FlexView>

        <FlexView wrap>
          {words.map(word => createTile(word, color))}
        </FlexView>
        
      </FlexView>
    )
  }

  function groupByChar(wordsArray) {
    console.log('wordsArray',wordsArray)
    if (wordsArray.length > 0) {
      const cats = wordsArray.reduce((cat, word) => {
        const newCat = cat
        const len = word.length
        // console.log('len',len)
        if (!newCat[len]) {
          newCat[len] = []
        }
        newCat[len].push(word)
        return newCat
      }, []) 
      console.log('cats', cats)
      return cats
    }

  }

  return (
    <FlexView column style={styles.parent}>
      {words.length > 0 ? groupByChar(words).reverse().map((wordCat, i) => createSection(wordCat, i)) : <div></div>}
    </FlexView>
  )
}