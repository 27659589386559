import React, {Component} from 'react'
import FlexView from 'react-flexview';
import * as firebase from "firebase/app"
import 'firebase/functions'
import Tiles from './components/Tiles'
import Navigation from './components/Navigation';
import Searchbar from './components/Searchbar';
import Footer from './components/Footer';
import SharedStyles from './components/SharedStyles.js'



export default class Find extends Component {
  
  // state={
  //   search: this.props.match.params.word.slice(0,15),
  //   words: []
  // }

  state={
    search: {
      word: this.props.match.params.chars1 || '',
      startsWith: this.props.match.params.chars2 || '',
      contains: this.props.match.params.chars3 || '',
      endsWith: this.props.match.params.chars4 || ''
    },
    words: [],
    content: {}
  }

  handleSearchSubmit = (event) => {
    event.preventDefault();
    const {word, startsWith, contains, endsWith} = this.state.search
    const url = `unscramble/${word}/starts-with/${startsWith}/contains/${contains}/ends-with/${endsWith}`
    var splitUrl = url.split('/')
    url.split('/').map((item, i) => {
      if (item === "") {
        splitUrl[i - 1] = ""
      }
    })
    const cleanedUrl = splitUrl.filter(item => item !== "").join('/')
    console.log('cleanedUrl', cleanedUrl)
    this.props.history.push(`/find/${cleanedUrl}`)
    this.unscrambleWord(word, startsWith, contains, endsWith)
  }

  handleSearchChange = (event, type) => {
    let searchState = this.state.search
    searchState[type] = event.target.value.slice(0,15)
    this.setState({search:searchState})
  }

  componentDidMount() {
    console.log('this.props.match.params',this.props.match.params)
    console.log('state',this.state)
    const {word} = this.state.search
    if (word) {
      console.log('unscrambling', word.slice(0,15))
      this.unscrambleWord(word.slice(0,15))
    }
  }


  unscrambleWord = async (word, startsWith, contains, endsWith) => {
    console.log('unscrambling')
    const firefunctions = firebase.functions()
    // console.log('firefunctions', firefunctions)
    const message = {word, startsWith, contains, endsWith};
    const wordsArray = await firefunctions.httpsCallable('unscramble')(message)
    // console.log('wordsArray', wordsArray.data.words)
    this.setState({words: wordsArray.data.words})
  }

  componentWillMount = async () => {
    const content = await Promise.resolve(this.props.content).then(snap => snap.val())
    console.log('content CDM', content)
    this.setState({content})
  }

  // handleChange = (event) => {
  //   if (event.target.value.length < 16) {
  //     this.setState({search: event.target.value})
  //   } else { 

  //   }
    
  // }
  // handleSubmit = (event) => {
  //   event.preventDefault();
  //   this.props.history.push(`/unscramble/${this.state.search}`)
  //   const {main, startsWith, contains, endsWith} = this.state.search
  //   this.unscrambleWord(main, startsWith, contains, endsWith)
  // }

  buildWordCount = () => {
    return (
      <FlexView hAlignContent='right' style={{...SharedStyles.h4, marginRight:'9vw'}}>
        <span style={{fontWeight:SharedStyles.h2.fontWeight}}>{this.state.words.length + '\xa0'}</span> 
          <text>words with letters </text>
        <span style={{fontWeight:SharedStyles.h2.fontWeight}}> {'\xa0' + this.state.search.word}</span>
      </FlexView>
    )
  }

  render() {
    // console.log('find', this)
    return (
      <FlexView name='parent' column={true} style={{height: '100%'}}>
        <Navigation screenSize={this.props.screenSize}/>
        <Searchbar
          heading={'Unscramble Any Word'}
          subtitle={'The fastest way to unscramble words for scrabble, Words with Friends etc.'}
          highlightedWord={'Unscramble'}
          onSubmit={this.handleSearchSubmit}
          search={this.state.search}
          onChange={this.handleSearchChange}
          screenSize={this.props.screenSize}/>
          {this.state.words.length > 0 ? this.buildWordCount() : null}
          <FlexView
            style={{marginLeft:'7vw',marginRight:'7vw', backgroundColor:'transparent'}}
            hAlignContent='center'
            vAlignContent='center'>
            <Tiles words={this.state.words}/>
        </FlexView>
        <Footer
          content={this.state.content['navigation']}
          screenSize={this.props.screenSize}
        />
      </FlexView>
    )
  }
}