import React, {Component} from 'react'
import FlexView from 'react-flexview';
import Radium from 'radium'; 
import SharedStyles from './SharedStyles.js'

const alphabet = "abcdefghijklmnopqrstuvwxyz".split('')
const numberArray = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine']


const styles = {
  parent: {
    // backgroundColor: 'red',
    margin:'2vw',
    // textAlign: 'left',
    // padding:'2vw',
    // paddingLeft: '5vw',
    // paddingRight: '5vw',
  },
  block: {
    // backgroundColor: '#F8F9FD',
    backgroundColor: 'white',
    margin:'1vw',
    textAlign: 'center',
    border: '2px solid transparent',
    borderRadius: '8px',
    // paddingLeft: '3vw',
    // paddingRight: '3vw',
    paddingTop: '1vw',
    paddingBottom: '1vw',
    // minWidth: '20vw'
    // minWidth: '32vw',
    // backgroundColor:'red'
  },
  heading: {
    fontSize: 'larger',
    fontWeight: '500',
    color: '#0E2641',
    // margin:'0.5vw',
    // marginLeft: '0vw',
    // marginRight: '0vw',
    width: '200px',
    // backgroundColor:'red'
    
  },
  list: {
    color: '#4B93E8',
    fontSize: 'small',
    lineHeight: '185%',
    textAlign:'left',
    textDecoration: 'none',
    // backgroundColor:'yellow'
    // marginLeft: '2vw',
    // marginRight: '2vw',
  },
  button: {
    color: SharedStyles.colors.black,
    fontSize: 'medium',
    textAlign:'center',
    textDecoration: 'none',
    border: '1px solid',
    borderColor: SharedStyles.colors.black,
    borderRadius: '20px',
    backgroundColor:'white',
    margin: '1vw',
    outline:'none',
    cursor: 'pointer',
    ':hover': {
      borderColor: SharedStyles.colors.blue,
      color: SharedStyles.colors.blue
    },
    // marginRight: '2vw',
  }
};

class WordLists extends Component {
  state = {
    allVisible : []
  }

  buttonClickHandler = (buttonIndex) => {
    console.log('buttin', buttonIndex)
    this.setState(prevState => {
      let newAllVisible = prevState.allVisible
      newAllVisible[buttonIndex] = !prevState.allVisible[buttonIndex]
      return {allVisible: newAllVisible}
    })
    console.log('state', this.state.allVisible)
  }

  generateSimilarSearches = (heading) => {
    const {searchChars} = this.props
    let newList = []
    let newLinks = []

    function gen(len, text) {
      const splitSearchChars = searchChars.indexOf(' and ') > -1 && text.indexOf('/') > -1 ? searchChars.split(' ').join('/') : searchChars
      if (len > 1) {
        // console.log('AND issue', text, searchChars)
        // if (searchChars.indexOf(' and ') > -1 && text.indexOf('/') > -1) {
          
        //   const splitSearchChars = searchChars.split(' ').join('/')
          // console.log('splitSearchChars', splitSearchChars)
        //   return alphabet.map(item => text + splitSearchChars.substring(0, splitSearchChars.length - 1) + item)
        // } else {
        return alphabet.map(item => text + splitSearchChars.substring(0, splitSearchChars.length - 1) + item)
        // }
      } else {
        return alphabet.map(item => text + splitSearchChars + item)
      }
    }

    if (heading.indexOf('Letter') > -1) { 
      const num = this.props.len
      if (heading.includes('Start')) {

        newList = gen(searchChars.length, num + 1 + ' letter words that start with ')
        newLinks = gen(searchChars.length, '/'+numberArray[num]+'-letter-words-starting-with/')
      } else if (heading.includes('End')) {
        newList = gen(searchChars.length, num + 1 + ' Letter words that end in ')
        newLinks = gen(searchChars.length, '/'+numberArray[num]+'-letter-words-ending-in/')
      } else {
        newList = gen(searchChars.length, num + 1 + ' letter words with ')
        newLinks = gen(searchChars.length, '/'+numberArray[num]+'-letter-words-with/')
      }  
    } else {
      if (heading.includes('Start')) {
        // console.log('searchChars.length start', searchChars.length)
        newList = gen(searchChars.length, 'Words that start with ')
        newLinks = gen(searchChars.length, '/words-start-with/')
      } else if (heading.includes('End')) {
        newList = gen(searchChars.length, 'Words that end in ')
        newLinks = gen(searchChars.length, '/words-end-in/')
      } else {
        newList = gen(searchChars.length, 'Words with ')
        newLinks = gen(searchChars.length, '/words-with/')
      }  
    }
    // console.log('{newList, newLinks}', {newList, newLinks})
    return {newList, newLinks}
  }

  generateBlock = (heading, list, links, i, isMobile) => {
    const allVis = this.state.allVisible[i]
    const {newList, newLinks} = heading.indexOf('Similar') > -1 ? this.generateSimilarSearches(heading) : {newList:list, newLinks:links}
    // console.log('list and links',newList, newLinks )
    const cutList = allVis ? newList : newList.slice(0, 5)
    return (
      <FlexView grow key={i} style={{...styles.block}} column hAlignContent="center" >
        <h3 style={SharedStyles.h3}>{heading}</h3>
        {cutList.map((item, r) => (<a key={i  + heading + r} style={SharedStyles.link} href={newLinks[r]}>{item}</a>))}
        {newList.length > 5 ? <button key={i  + heading} index={i} onClick={() => this.buttonClickHandler(i)} style={styles.button}>{ allVis ? 'See Less' : 'See More'}</button> : null}
      </FlexView>
    )
  }
  
  render() {
    const isMobile = this.props.screenSize.width < 640
    return (
      <FlexView wrap column={false} style={styles.parent} vAlignContent='top' hAlignContent='center'>
        {this.props.content.map((item, i) => this.generateBlock(item.heading, item.list, item.links, i, isMobile))}
      </FlexView>
    )
  }

}

export default Radium(WordLists)
