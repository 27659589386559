import React, {Component} from 'react'
import FlexView from 'react-flexview';
// import * as firebase from "firebase/app"
// import 'firebase/functions'
import Navigation from './components/Navigation';
import Searchbar from './components/Searchbar';
import Footer from './components/Footer';
import footerContent from './content/footer.json'

class Unscramble extends Component {

  state={
    search: {
      word: '',
      startsWith: '',
      contains: '',
      endsWith: ''
    }
  }

  handleSearchSubmit = (event) => {
    event.preventDefault();
    this.props.history.push(`/unscramble/${this.state.search.word}`)
    // const {main, startsWith, contains, endsWith} = this.state.search
    // this.unscrambleWord(main, startsWith, contains, endsWith)
  }

  handleSearchChange = (event, type) => {
    let searchState = this.state.search
    searchState[type] = event.target.value.slice(0,15)
    this.setState({search:searchState})
  }

  render() {
    return (
      <FlexView name='parent' column={true} style={{height: '100%'}}>
        <Navigation screenSize={this.props.screenSize}/>
        <Searchbar
          heading={'Unscramble Any Word'}
          subtitle={'The fastest way to unscramble words for scrabble, Words with Friends etc.'}
          highlightedWord={'Unscramble'}
          onSubmit={this.handleSearchSubmit}
          search={this.state.search}
          onChange={this.handleSearchChange}
          screenSize={this.props.screenSize}/>
        <Footer
          content={footerContent.content}
          screenSize={this.props.screenSize}
        />
      </FlexView>
    )
  }

}

export default Unscramble