import React from 'react'
import FlexView from 'react-flexview'
import SharedStyles from './SharedStyles.js'
import {Link} from 'react-router-dom'

const styles = {
  block: {
    marginTop: '0px',
    // marginLeft:'6vw',
    // marginRight:'6vw',
    textAlign: 'center',
    width:'70vw',
    maxWidth: SharedStyles.gContent.maxWidth,
    // 
    // paddingLeft: '5vw',
    // paddingRight: '5vw',
    // paddingTop: '1vw',
    // paddingBottom: '1vw',
    // backgroundColor: 'red'
  }
};

const buildHead = (text, size, color) => {
  switch (size) {
    case 'h1':
      return (
        <h1 style={{...SharedStyles.h1, color}}>{text}</h1>
      )
    case 'h2':
      return (
        <h2 style={{...SharedStyles.h2, color}}>{text}</h2>
      )
    case 'h3':
      return (
        <h3 style={{...SharedStyles.h3, color}}>{text}</h3>
      )
    default:
      return <h2 style={{...SharedStyles.h2, color}}>{text}</h2>
  }
}

const addURL = (body) => {
  const newBody = body.map((item, i) => {
    if (i % 2) {
      const text = item.replace(/ *\([^)]*\) */g, "")
      const url = item.match(/\((.*?)\)/)[1] 
      const whichLink = url.includes('.') ? <a style={{textDecoration: 'none'}} href={url}>{text}</a> : <Link to={url}>{text}</Link>
      return whichLink
      
    } else {
      return (item)
    }
  })
  return newBody
}

const Heading = props => {
  // const isMobile = props.screenSize.width < 640
  const alignment = props.align ? props.align : 'center'
  console.log('textAlign', alignment)
  const color = props.color ? '#4B93E8' : '#041847'

  return (
    <FlexView style={{...styles.block, textAlign:alignment}} column vAlignContent='center' hAlignContent={alignment}>
      {buildHead(addURL(props.heading.split('*')), props.size, color)}
    </FlexView>
  )
}

export default Heading;
