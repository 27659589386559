import React from 'react';
import {Helmet} from 'react-helmet';

const MetaTags = props => {
    const { metaData } = props;
    return (
        <Helmet>
            {metaData.title ? <title>{metaData.title}</title> : null}
            {metaData.description ? <meta property="og:description" name="description" content={metaData.description} /> : null}
            {metaData.keywords ? <meta  name="keywords" content={metaData.keywords} /> : null}
            <meta property="og:image" content="/logo512.png" />
            {metaData.robots ? <meta name="robots" content={metaData.robots} /> : null}
        </Helmet>
    );
};

export default MetaTags