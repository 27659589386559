import React, {Component} from 'react'
import FlexView from 'react-flexview';
import search from './../images/search.svg';
import { ReactComponent as Logo } from './../images/logo.svg'

const colors = {
  green: '#9ED6A4',
  blue: '#6FC3F3',
  purple: '#D893E6',
  yellow: '#F5CF80',
  red: '#FEBBA6',
  grey: '#9A9A9A',
  black: '#041847',
  darkBlue: '#4B93E8'
}

const styles = {
  searchBox: {
    // zIndex: 0,
    backgroundColor:'transparent',
    padding:'20px',
    width: '100vw',
    // height:'60vh'
  },
  searchbar: {
    WebkitAppearance: 'none',
    outline: 'none',
    boxShadow: 'none !important',
    borderStyle: 'none',
    backgroundColor: 'transparent',
    borderBottom: '2px solid black',
    maxWidth: '70vw',
    fontSize: '200%',
    color: '#041847',
    backgroundImage: 'url(' + search + ')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left',
    marginTop: '20px',
    padding: '5px',
    paddingLeft: '25px',
    textAlign: 'center'
  },
  subSearchbar: {
    WebkitAppearance: 'none',
    outline: 'none',
    boxShadow: 'none !important',
    borderStyle: 'none',
    backgroundColor: 'transparent',
    borderBottom: '2px solid black',
    fontSize: '14px',
    marginTop: '20px',
    margin: '5px',
    padding: '5px',
    textAlign: 'center',
    width: '80px',
    maxWidth: '15vw',
    color: colors.black
    // opacity: '60%'
  },
  button : {
    width: '180px',
    backgroundColor: '#4B93E8',
    fontSize: '20px',
    fontWeight: '420',
    color: 'white',
    border: '1px solid transparent',
    padding: '4px',
    marginBottom: '20px',
    borderRadius: 3,
    cursor: 'pointer',
    outline: 'none',
  }
}

export default class Searchbar extends Component {

  state = {
    search: this.props.search,
    buttonColor: colors.darkBlue
    // mainFocus: false
  }

  generateHeading = (heading, highlight) => {
    // console.log('this.props.', this.props)
    const sizesEr = this.props.screenSize ? this.props.screenSize.width : {width: 1125, height: 798}
    const isMobile = sizesEr < 640
    const fontSize = isMobile ? '40px' : '50px'
    if (highlight) {
      const spliter = heading.split(highlight)
      return (<h1 style={{fontSize, marginBottom: '0px', textAlign: 'center'}}>{spliter[0]}<span style={{color:'#4B93E8'}}>{highlight}</span>{spliter[1]}</h1>)
    } else {
      return (<h1 style={{fontSize, marginBottom: '0px', textAlign: 'center'}}>{heading}</h1>)
    }
  }

  componentDidMount = () => {
    if (this.props.search) {
      const {startsWith, contains, endsWith, length} = this.props.search
      this.setState({
        startsWithFocus: startsWith.length > 0,
        containsFocus: contains.length > 0,
        endsWithFocus: endsWith.length > 0,
        lengthFocus: length > 0
      })
    }
  }

  switchFocus = (input, bol) => {
    const inputFocus = input.concat('Focus')
    // console.log(this.props.search[input].length > 0)
    if (this.props.search[input].length) {
      //do nothing
      console.log('doing nothing')
    } else if (this.props.search[input] > 0) {

    } else {
      this.setState({[inputFocus]:bol})
    }
    
  }

  handleSearchChange = (event, type) => {
    let searchState = this.state.search
    console.log('handleSearchChange', type, event.target.value)
    if (type === 'length') {
      searchState[type] = parseInt(event.target.value) || 0
    } else {
      searchState[type] = event.target.value.slice(0,15).toUpperCase()
    }
    this.setState({search:searchState})
  }

  submitSearch = (e) => {
    if (e.key === 'Enter') {
      console.log('enter was pressed')
      this.props.onSubmit(this.state.search)
    }
  }

  toggleButton = (e) => {
    console.log('buttin', e.type)
    if (e.type === 'mousedown') {
      this.setState({buttonColor:'#3275C4'})
    } else {
      this.setState({buttonColor:colors.darkBlue})
    }
    
  }

  render() {
    console.log('state of searchbar', this.props.search)
    
    const mainBorder = this.state.wordFocus ? colors.blue : colors.black
    const startsWithOpacity = this.state.startsWithFocus ? '100%' : '30%'
    const containsOpacity = this.state.containsFocus ? '100%' : '30%'
    const endsWithOpacity = this.state.endsWithFocus ? '100%' : '30%'
    const lengthOpacity = this.state.lengthFocus ? '100%' : '30%'
    // console.log('mainBorder', mainBorder)
    return (
      <FlexView column hAlignContent='center' vAlignContent='center' style={styles.searchBox}>
        {/* <Logo/> */}
       {this.generateHeading(this.props.heading, this.props.highlightedWord)}
        <p style={{marginTop:'10px', color:'#989797', fontSize: '16px', textAlign: 'center'}}>{this.props.subtitle}</p>
        <FlexView column hAlignContent='center' style={{height:'20vh', width:'70vw'}}>
            <input
              style={{...styles.searchbar, borderColor: mainBorder}}
              type="text"
              name="title"
              placeholder={'Your Letters'}
              value={this.state.search ? this.state.search.word : ''}
              onChange={e => this.handleSearchChange(e, 'word')}
              onFocus={() => this.switchFocus('word', true)}
              onBlur={() => this.switchFocus('word', false)}
              onKeyPress={this.submitSearch}
            />
          <FlexView style={{marginTop:'15px'}}>
            <input
              style={{...styles.subSearchbar, borderColor:colors.green, opacity:startsWithOpacity}}
              type="text"
              name="startsWith"
              placeholder={'Starts With'}
              value={this.state.search ? this.state.search.startsWith : ''}
              onChange={e => this.handleSearchChange(e, 'startsWith')}
              onFocus={() => this.switchFocus('startsWith', true)}
              onBlur={() => this.switchFocus('startsWith', false)}
              onKeyPress={this.submitSearch}
            />
            <input
              style={{...styles.subSearchbar, borderColor:colors.yellow, opacity:containsOpacity}}
              type="text"
              name="title"
              placeholder={'Contains'}
              value={this.state.search ? this.state.search.contains : ''}
              onChange={e => this.handleSearchChange(e, 'contains')}
              onFocus={() => this.switchFocus('contains', true)}
              onBlur={() => this.switchFocus('contains', false)}
              onKeyPress={this.submitSearch}
            />
            <input
              style={{...styles.subSearchbar, borderColor:colors.purple, opacity:endsWithOpacity}}
              type="text"
              name="title"
              placeholder={'Ends With'}
              value={this.state.search ? this.state.search.endsWith : ''}
              onChange={e => this.handleSearchChange(e, 'endsWith')}
              onFocus={() => this.switchFocus('endsWith', true)}
              onBlur={() => this.switchFocus('endsWith', false)}
              onKeyPress={this.submitSearch}
            />
            <input
              style={{...styles.subSearchbar, borderColor:colors.red, opacity:lengthOpacity}}
              type="text"
              name="title"
              placeholder={'Length'}
              value={this.state.search ? this.state.search.length > 0 ? this.state.search.length : '' : ''}
              onChange={e => this.handleSearchChange(e, 'length')}
              onFocus={() => this.switchFocus('length', true)}
              onBlur={() => this.switchFocus('length', false)}
              onKeyPress={this.submitSearch}
            />
          </FlexView>
        </FlexView>
        <FlexView>
          <button
              onMouseDown = {this.toggleButton}
              onMouseUp = {this.toggleButton}
              onClick={() => this.props.onSubmit(this.state.search)}
              style={{...styles.button, backgroundColor: this.state.buttonColor}}>SEARCH</button>
        </FlexView>

      </FlexView>
    )
  }
}