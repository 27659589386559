import React, {Component} from 'react'
import FlexView from 'react-flexview';
import Radium from 'radium';
import SharedStyles from './SharedStyles.js'

const styles = {
  parent: {
    // backgroundColor: 'red',
    margin:'3vw',
    border: '1px solid #4B93E8',
    borderRadius: '8px',
    padding:'2vw',
    // paddingLeft: '2vw',
    // paddingRight: '2vw',
    maxWidth: SharedStyles.gContent.maxWidth
    // textAlign: 'left',
    
    // paddingLeft: '5vw',
    // paddingRight: '5vw',
  },
  block: {
    // backgroundColor: '#F8F9FD',
    // backgroundColor: 'white',
    margin:'1vw',
    textAlign: 'left',
    // paddingLeft: '3vw',
    // paddingRight: '3vw',
    paddingTop: '1vw',
    paddingBottom: '1vw',
    // minWidth: '20vw'
    // minWidth: '32vw',
    // backgroundColor:'grey'
  },
  heading: {
    fontSize: 'larger',
    fontWeight: '500',
    color: '#0E2641',
    paddingLeft: '3vw',
    paddingRight: '3vw',
    // margin:'0.5vw',
    // marginLeft: '0vw',
    // marginRight: '0vw',
    // width: '200px',
    // backgroundColor:'red'
    
  },
  list: {
    color: '#4B93E8',
    fontSize: '15px',
    // fontWeight: 'bold',
    lineHeight: '185%',
    textAlign:'left',
    textDecoration: 'none',
    ':hover': {
      color:'#FF4073',
      // backgroundColor: '#3A94CF'
    },
    // backgroundColor:'yellow',
    // marginLeft: '2vw',
    // marginRight: '2vw',
  }
}

class Links extends Component {

  chunk = (array, size, links) => {
    console.log('chunking', array, size, links)
    const chunked_arr = [];
    for (let i = 0; i < array.length; i++) {
      const last = chunked_arr[chunked_arr.length - 1];
      if (!last || last.length === size) {
        chunked_arr.push([{list:array[i], link:links[i]}]);
      } else {
        last.push({list:array[i], link:links[i]});
      }
    }
    return chunked_arr;
  }

  createColumn = (list, i) => {
    return (
      <FlexView key={i} grow style={styles.block} column>
        {list.map((item, r) => (<a key={(i + 1) * 100 + r} style={{...SharedStyles.link, lineHeight: '160%', marginBottom: '10px'}} href={item.link}>{item.list}</a>))}
      </FlexView>
    )
  }
  
  render() {
    // const isMobile = this.props.screenSize.width < 640
    const columns = Math.floor(this.props.screenSize.width / 250)
    const chunks = this.chunk(this.props.list, Math.ceil(this.props.list.length / columns), this.props.links)
    console.log('chunksLinks', chunks)
    return (
      <FlexView column style={{...styles.parent, width:this.props.screenSize.width * 0.9}} vAlignContent='top' hAlignContent='left'>
        <h2 style={{...SharedStyles.h2}}>{this.props.heading}</h2>
        <FlexView column={false} style={{width:'100%'}}>
          {chunks.map((item, i) => this.createColumn(item, i))}
        </FlexView>
      </FlexView>
    )
  }

}

export default Radium(Links)
