import React, {Component} from 'react'
import FlexView from 'react-flexview';
import SharedStyles from './SharedStyles.js'
import Radium from 'radium';
import { ReactComponent as Logo } from './../images/logo.svg'

const disclaimer = 'Scrabble®, Words with Friends®, Word Cookies®, Word Chums®, Text Twist®, Word Cookies®, and Jumble® are registered trademarks and the property of each respective game owner. All intellectual property for Scrabble® is owned in the USA and Canada by Hasbro Inc, and in the rest of the world by J.W. Spear & Sons Ltd of Berkshire England, a subsidiary of Mattel Inc. Words with Friends® is a registered trademark of Zynga. WordRater.com is not affiliated in any way with Scrabble®, Mattel Inc, J.W. Spear & Sons, Zynga, or any other gaming company mentioned therein. This site should be used for informational and entertainment purposes only.'

const styles = {
  parent: {
    backgroundColor: '#F8F9FD',
    width: '100%',
    // margin:'2vw',
    // textAlign: 'left',
    padding:'1.5vw',
    // paddingLeft: '5vw',
    // paddingRight: '5vw',
  },
  block: {
    // backgroundColor: '#F8F9FD',
    // backgroundColor: 'blue',
    margin:'0px',
    // width: '20vw',
    textAlign: 'left',
    // padding: '1vw',
    // paddingLeft: '3vw',
    // paddingRight: '3vw',
    // paddingTop: '1vw',
    // paddingBottom: '1vw',
    // minWidth: '20vw'
    // minWidth: '32vw',
    // backgroundColor:'red'
  },
  heading: {
    margin:'0px',
    // fontSize: 'small',
    // fontWeight: '500',
    color: SharedStyles.colors.blue,
    marginBottom:'0.5vw',
    // marginLeft: '0vw',
    // marginRight: '0vw',
    width: '140px',
    // backgroundColor:'red'
    
  },
  list: {
    // color: '#737373',
    // fontSize: '12px',
    // lineHeight: '135%',
    textAlign:'left',
    textDecoration: 'none',
    width:'140px',
    ':hover': {
      color:'#4B93E8',
    },
    // backgroundColor:'yellow'
    // marginLeft: '2vw',
    // marginRight: '2vw',
  },
  crumb: {
    margin: '1vw',
    textDecoration: 'none',
    ':hover': {
      color:'#4B93E8',
    },
    fontSize: '10px'
  },
  disclaimer: {
    fontSize: '10px',
    color: '#737373',
    margin: '2vw'
  },
  logo : {
    color:'#4B93E8',
    textDecoration: 'none',
    fontSize: '1.5rem',
    fontFamily: 'Helvetica Neue',
    fontWeight: 'bold',
    // paddingLeft: '12px'
  },
  icon: {
    padding:'2vw',
    // paddingRight:'2vw',
    // backgroundColor: 'red'
    // width:'40vw',
    // height:'40vw'
  }
};

const urlDic = {
  'words-start-with' : [
    'Words That Start With',
    'www.wordrater.com/words-start-with'
  ],
  'words-with-letters' : [
    'Words With Letters',
    'www.wordrater.com/words-with-letters'
  ],
  'words-with' : [
    'Words With',
    'www.wordrater.com/words-with'
  ],
  'words-end-in' : [
    'Words End In',
    'www.wordrater.com/words-end-in'
  ],
}

class Footer extends Component {

  generateBreadcumbs = (url) => {
    const crumb = url.split('/')[1]
    const crumb2 = url.split('/')[2] || ''
    // const urlKeys = Object.keys(urlDic)
    // return null
    console.log('urlDic', urlDic[crumb])
    console.log('crumb', crumb)
    return (
      <FlexView hAlignContent='center' style={{marginRight:'2vw'}}>
        <a key={'home'} style={styles.crumb} href='www.wordrater.com'>HOME</a>
        <FlexView style={styles.crumb}>/</FlexView>
        <a key={urlDic[crumb][0]} style={styles.crumb} href={urlDic[crumb][1]}>{urlDic[crumb][0].toUpperCase() + ' ' + crumb2.toUpperCase()}</a>
      </FlexView>
    )
    // return (
    //   urlArray.map(item => {
    //     if (urlDic[item]) {
    //       return (<a href={urlDic[item][1]}>{urlDic[item][0]}</a>)
    //     } else {
    //       return null
    //     }
    //   })
    // )
  }

  generateBlock = (heading, list, links, i, isMobile) => {
    return (
      <FlexView grow key={i} style={{...styles.block}} column hAlignContent="center" >
        <h4 style={{...SharedStyles.h4, ...styles.heading}}>{heading}</h4>
        {list.map((item, r) => (<a key={i + heading + item + r} style={{...SharedStyles.h5, ...styles.list}} href={links[r]}>{item}</a>))}
      </FlexView>
    )
  }
  
  render() {
    let formatted = []
    if (this.props.content) {
      
      const footer = Object.keys(this.props.content).map(i => this.props.content[i]).filter(item => item.type === 'Footer')[0]
      console.log('footer', footer)
      const noLists = Object.keys(footer).filter(item => item.indexOf('list') > -1)
      formatted = noLists.map((item, i) => ({heading: footer['heading' + (i + 1)], list: footer['list' + (i + 1)].split(', '), links: footer['links' + (i + 1)].split(', ')}))
      // console.log('toolbar Object.keys(pageContent)', Object.keys(this.props.content).map(i => this.props.content[i]))
    }
    const isMobile = this.props.screenSize.width < 640
    const {url} = this.props
    return (
      <FlexView column>
        {url ? this.generateBreadcumbs(url) : null}
        <p style={styles.disclaimer}>{disclaimer}</p>
        <FlexView column style={styles.parent} hAlignContent='center'>
          <FlexView wrap column={false} style={styles.parent} vAlignContent='top' hAlignContent='center'>
            <FlexView column hAlignContent={'center'}>
              <a style={styles.logo} href="/">WORDRATER</a>
              <Logo style={{...styles.icon, width: '20vw'}}/>
            </FlexView>
            {formatted.map((item, i) => this.generateBlock(item.heading, item.list, item.links, i, isMobile))}
          </FlexView>
          <p style={{color: '#737373', fontSize: '12px',}}>© 2020 Copyright: Wordrater</p>
        </FlexView>
      </FlexView>
    )
  }

}

export default Radium(Footer)
