import React from 'react';
import Radium,{StyleRoot} from 'radium';
import Home from './Home'

import {Route, Switch} from 'react-router-dom'
import useResizeAware from 'react-resize-aware';

//testing
import Unscramble from './Unscramble'
import Find from './Find'
import PageGenerator from './PageGenerator'


import WordsStartWith from './WordsStartWith'
import WordsWith from './WordsWith'
import WordsEndIn from './WordsEndIn'

//database
import * as firebase from "firebase/app"
import "firebase/database";
const fs = require('fs')


var config = {
  apiKey: "AIzaSyC5BCjczxzA_NvwIPudES8UXCTKv5JlsUM",
  authDomain: "translate-2-f39f4.firebaseapp.com",
  databaseURL: "https://translate-2-f39f4.firebaseio.com",
  projectId: "translate-2-f39f4",
};

firebase.initializeApp(config);



function getContent() {
  const db = firebase.database()
  const contentPromise = db.ref().once('value')
  return contentPromise
}

function App() {
  const content = getContent()
  const [resizeListener, sizes] = useResizeAware()
  // console.log('sizes',sizes)
  const sizesEr = sizes.width !== null ? sizes : {width: 1125, height: 798}
  // console.log('sizesEr',sizesEr)
  // const content = getContent()
  // console.log('content',content)
  return (
    <StyleRoot>
      {resizeListener}
      <div className="App">
        {/* <Route exact path="/" render={routeProps => (<Home {...routeProps} {...{screenSize:sizesEr}} />)}/> */}
        <Switch>
          {/* <Route exact path="/find/:filter1?/:chars1?/:filter2?/:chars2?/:filter3?/:chars3?/:filter4?/:chars4?" render={routeProps => (<Find {...routeProps} {...{screenSize:sizesEr, content}} />)}/> */}
          <Route path="/:url?/:chars1?/:filter2?/:chars2?/:filter3?/:chars3?/:filter4?/:chars4?" render={routeProps => (<PageGenerator {...routeProps} {...{screenSize:sizesEr, content}} />)}/>
        </Switch>
        {/* <Route exact path="/unscramble" render={routeProps => (<Unscramble {...routeProps} {...{screenSize:sizesEr}} />)}/> */}
        {/* <Route path="/words-start-with/:chars" render={routeProps => (<WordsStartWith {...routeProps} {...{screenSize:sizesEr}} />)}/>
        <Route path="/words-with/:chars" component={WordsWith} />
        <Route path="/words-end-in/:chars" component={WordsEndIn} /> */}
      </div>
    </StyleRoot>
  );
}

export default Radium(App);
