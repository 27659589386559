import React, {Component} from 'react'
import FlexView from 'react-flexview';
// import * as firebase from "firebase/app"
// import 'firebase/functions'
import './App.css';

console.log('in unscrambled.js');

export default class Unscramble extends Component {
  
  state={
    search: 'search',
    words: []
  }

  componentDidMount() {
    console.log(this.props.match.params.chars)
  }


  handleChange = (event) => {
    this.setState({search: event.target.value})
  }
  handleSubmit = (event) => {
    console.log(this.props.match.params.chars)
    event.preventDefault();
    this.props.history.push(`/words-end-in/${this.state.search}`)
    // this.unscrambleWord(this.state.search)
  }

  render() {
    
    return (
      <FlexView name='parent' column={true}>
        <FlexView
          className="Top"
          column={true}
          hAlignContent='center'
          vAlignContent='center'
          >
          <h1>Unscramble</h1>
          <form onSubmit={this.handleSubmit}>
            <input
              className='searchbar'
              type="text"
              name="title"
              value={this.state.search}
              onChange={this.handleChange}
              onClick={() => this.setState({search: ''})}
            />
          </form>
        </FlexView>
        <FlexView
          style={{marginLeft:'10vw',marginRight:'10vw', backgroundColor:'transparent'}}
          hAlignContent='center'
          vAlignContent='center'>
          <FlexView>{this.props.match.params.chars}</FlexView>
        </FlexView>
        
      </FlexView>
    )
  }

}