import React from 'react'
import FlexView from 'react-flexview';
import SharedStyles from './SharedStyles.js'
import {Link} from 'react-router-dom'

const styles = {
  block: {
    // margin: '1vw',
    marginTop: '0px',
    // marginLeft:'6vw',
    // marginRight:'6vw',
    textAlign: 'center',
    // paddingLeft: '5vw',
    // paddingRight: '5vw',
    // paddingTop: '1vw',
    // paddingBottom: '1vw',
    // backgroundColor: 'red'
    maxWidth: SharedStyles.gContent.maxWidth,
    width:'70vw'
  }
};

function insertJSX(array, search, replacement) {
  const newJSXarray = array.map(item => {
    const splitItem = item.type ? [item] : item.split(search)
    if (splitItem.length > 1) {
      const newLength = splitItem.length + (splitItem.length - 1)
      const newArray = [...Array(newLength)].map((item, i) => {
        if (i % 2) {
        return (replacement)
        } else {
          return splitItem[i / 2]
        }
      })
      return newArray
    } else {
      return item
    }
  })
  const flatten = newJSXarray.flat()
  return flatten
}

function addURL(body) {
  const newBody = body.map((item, i) => {
    if (i % 2) {
      const text = item.replace(/ *\([^)]*\) */g, "")
      const url = item.match(/\((.*?)\)/)[1] 
      const whichLink = url.includes('.') ? <a href={url}>{text}</a> : <Link to={url}>{text}</Link>
      return whichLink
      
    } else {
      return (item)
    }
  })
  return newBody
}

const insertHeading = (array, search) => {
  const newJSXarray = array.map(item => {
    const splitContent = typeof item != 'string' ? [item] : item.split(search)
    const body = splitContent.map((item, i) => {
      if (i % 2) {
        return (<h3 style={{...SharedStyles.h3}}>{item}</h3>)
      } else {
        return (item)
      }
    })
    return body
  })
  return newJSXarray
}

const insertBold = (array, search) => {
  console.log('insertBold', array)
  const newJSXarray = array.map(item => {
    console.log('item', item)
    const splitContent = typeof item[0] != 'string' ? item : item[0].split(search)
    console.log('splitContent', splitContent)
    const body = splitContent.map((item, i) => {
      if (i % 2) {
        return (<span style={{fontWeight:'bold'}}>{item}</span>)
      } else {
        return (item)
      }
    })
    return body
  })
  return newJSXarray
}

function buildPara(string) {
  const body = addURL(string.split('*'))
  const addBreak = insertJSX(body, '/b', [<FlexView style={{padding:'0px', marginBottom:'12px'}}></FlexView>])
  const addHeadings = insertHeading(addBreak, '#h')
  const addBold = insertBold(addHeadings, '#b')
  return addBold
}

const Paragraph = props => {
  // const isMobile = props.screenSize.width < 640
  const alignment = props.align ? props.align : 'center'
  console.log('alignment', alignment)
  const color = props.color ? '#4B93E8' : '#3E3E3E'

  return (
    <FlexView style={{...styles.block, textAlign:alignment}} column vAlignContent='center' hAlignContent='center'>
      <p style={{...SharedStyles.paragraph, color}}>{buildPara(props.paragraph)}</p>
    </FlexView>
  )
}

export default Paragraph;
