import React from 'react'
import FlexView from 'react-flexview';
import Radium from 'radium';
import SharedStyles from './SharedStyles.js'
import {Link} from 'react-router-dom'

import { ReactComponent as Icon1 } from './../images/gameboy.svg'
import { ReactComponent as Icon2 } from './../images/icecream.svg'
import { ReactComponent as Icon3 } from './../images/egg.svg'
import { ReactComponent as Icon4 } from './../images/cactus.svg'
import { ReactComponent as Icon5 } from './../images/pancake.svg'

const styles = {
  parent: {
    // backgroundColor: 'red',
    margin:'2vw',
    textAlign: 'left',
    // padding:'2vw',
    // paddingLeft: '5vw',
    // paddingRight: '5vw',
    maxWidth: SharedStyles.gContent.maxWidth
  },
  block: {
    backgroundColor: 'transparent',
    margin:'1vw',
    textAlign: 'left',
    border: '2px solid transparent',
    borderRadius: '8px',
    paddingLeft: '3vw',
    paddingRight: '3vw',
    paddingTop: '1vw',
    paddingBottom: '1vw',
  },
  more: {
    color:'#4B93E8',
    textAlign:'right',
    textDecoration: 'none',
    ':hover': {
      color:'#FF4073',
      // backgroundColor: '#3A94CF'
    },
  },
  icon: {
    paddingLeft:'2vw',
    paddingRight:'2vw',
    // backgroundColor: 'red'
    // width:'40vw',
    // height:'40vw'
  }
};

function insertJSX(array, search, replacement) {
  const newJSXarray = array.map(item => {
    const splitItem = item.type ? [item] : item.split(search)
    if (splitItem.length > 1) {
      const newLength = splitItem.length + (splitItem.length - 1)
      const newArray = [...Array(newLength)].map((item, i) => {
        if (i % 2) {
        return (replacement)
        } else {
          return splitItem[i / 2]
        }
      })
      return newArray
    } else {
      return item
    }
  })
  const flatten = newJSXarray.flat()
  return flatten
}

function addURL(body) {
  const newBody = body.map((item, i) => {
    if (i % 2) {
      const text = item.replace(/ *\([^)]*\) */g, "")
      const url = item.match(/\((.*?)\)/)[1] 
      const whichLink = url.includes('.') ? <a href={url}>{text}</a> : <Link to={url}>{text}</Link>
      return whichLink
      
    } else {
      return (item)
    }
  })
  return newBody
}

const insertHeading = (array, search) => {
  const newJSXarray = array.map(item => {
    const splitContent = typeof item != 'string' ? [item] : item.split(search)
    const body = splitContent.map((item, i) => {
      if (i % 2) {
        return (<h3 style={{...SharedStyles.h3}}>{item}</h3>)
      } else {
        return (item)
      }
    })
    return body
  })
  return newJSXarray
}

function buildPara(string) {
  const body = addURL(string.split('*'))
  const addBreak = insertJSX(body, '/b', [<FlexView style={{padding:'0px', marginBottom:'12px'}}></FlexView>])
  const addHeadings = insertHeading(addBreak, '#h')
  return addHeadings
}


const SummaryBlocks = props => {
  const isMobile = props.screenSize.width < 640
  const alignText = isMobile ? 'center' : 'left'
  
  
  const generateBlock = (heading, body, i, isMobile) => {
    let alignText = isMobile ? 'center' : i % 2 ? 'left' : 'right'
    // const maxTextWidth = isMobile ? '100vw' : '46vw'
    // const isColored = (i / 2) % 2 && ((i+1) / 2) % 2 ? '#F8F9FD' : 'transparent'
    // const isColoredMobile = i % 2 ? '#F8F9FD' : 'transparent'
    // const backColor = isMobile ? isColoredMobile : isColored
    const maxWidthPX = parseFloat(SharedStyles.gContent.maxWidth.replace(/\D/g,''))
    const newScreenWidth = maxWidthPX > props.screenSize.width ? props.screenSize.width : maxWidthPX
    const halfScreen = newScreenWidth * 0.8 / 2
    const icons = [
      (<Icon1 style={{...styles.icon, width: halfScreen + 'px'}}/>),
      (<Icon2 style={{...styles.icon, width: halfScreen + 'px'}}/>),
      (<Icon3 style={{...styles.icon, width: halfScreen + 'px'}}/>),
      (<Icon4 style={{...styles.icon, width: halfScreen + 'px'}}/>),
      (<Icon5 style={{...styles.icon, width: halfScreen + 'px'}}/>)
    ]
    if (isMobile) {
      return (
        <FlexView column key={i} style={{...styles.block}} >
          <FlexView vAlignContent='center'>{icons[i]}</FlexView>
          
          <FlexView column hAlignContent={isMobile ? 'center' : 'left'} style={{width: halfScreen + 'px', textAlign: alignText}}>
            <h2 style={{...SharedStyles.h2, width: '70vw'}}>{heading}</h2>
            <p style={{...SharedStyles.paragraph, width: '70vw'}}>{buildPara(body)}</p>
          </FlexView>
          {/* <FlexView vAlignContent='center'>{ i % 2 ? null : icons[i]}</FlexView> */}
        </FlexView>
      )
    } else {
      return (
        <FlexView key={i} style={{...styles.block}} >
          <FlexView vAlignContent='center'>{ i % 2 ? icons[i]: null }</FlexView>
          
          <FlexView column hAlignContent={isMobile ? 'center' : 'left'} style={{width: halfScreen + 'px', textAlign: alignText}}>
            <h2 style={{...SharedStyles.h2, width: halfScreen + 'px'}}>{heading}</h2>
            <p style={SharedStyles.paragraph}>{buildPara(body)}</p>
          </FlexView>
          <FlexView vAlignContent='center'>{ i % 2 ? null : icons[i]}</FlexView>
        </FlexView>
      )
    }

    // }
    // return (
    //   <FlexView key={i} style={{...styles.block, textAlign: alignText, maxWidth: maxTextWidth, backgroundColor: backColor}} column >
    //     <FlexView column hAlignContent={isMobile ? 'center' : 'left'}>
    //       <h3 style={styles.heading}>{heading}</h3>
    //       <p style={styles.body}>{body}</p>
    //     </FlexView>
    //     {/* <FlexView hAlignContent='right'>
    //       <a key={i} style={styles.more} href="https://www.w3schools.com">Read More</a>
    //     </FlexView> */}
    //   </FlexView>
    // )
  }
  

  return (
    <FlexView column style={styles.parent} vAlignContent='center' hAlignContent='center'>
      {props.content.map((item, i) => generateBlock(item.heading, item.body, i, isMobile))}
      {/* <FlexView style={{textAlign: alignText}} wrap={isMobile} column={false}>
      </FlexView> */}
    </FlexView>
  )
}

export default Radium(SummaryBlocks);
