const styles = {
  paragraph: {
    color: '#041847', 
    fontSize: 'large',
    fontWeight: '400',
    lineHeight: '220%',
    marginTop: '0px',
  },
  h1: {
    color: '#041847', 
    fontSize: 'xxx-large',
    fontWeight: '500',
    fontFamily: 'Helvetica Neue',
  },
  h2: {
    color: '#041847', 
    fontSize: 'xx-large',
    fontWeight: '450',
    fontFamily: 'Helvetica Neue',
  },
  h3: {
    color: '#041847', 
    fontSize: 'x-large',
    fontWeight: '420',
    fontFamily: 'Helvetica Neue',
  },
  h4: {
    color: '#041847', 
    fontSize: 'large',
    fontWeight: '400',
    fontFamily: 'Helvetica Neue',
  },
  h5: {
    color: '#041847', 
    fontSize: 'medium',
    fontWeight: '370',
    fontFamily: 'Helvetica Neue',
  },
  link: {
    color: '#4B93E8',
    fontSize: 'medium',
    lineHeight: '220%',
    textDecoration: 'none',
    ':hover': {
      color:'#FF4073',
    },
  },
  colors: {
    blue: '#4B93E8',
    black: '#041847',
    red:'#FF4073',
    grey: '#4B5567'
  },
  gContent: {
    maxWidth: '1000px'
  },
  button: {
    color: '#041847',
    fontSize: 'medium',
    textAlign:'center',
    textDecoration: 'none',
    border: '1px solid',
    borderColor: '#041847',
    borderRadius: '20px',
    backgroundColor:'white',
    margin: '1vw',
    outline:'none',
    cursor: 'pointer',
    ':hover': {
      borderColor: '#4B93E8',
      color: '#4B93E8'
    },
    // marginRight: '2vw',
  }
};

export default styles