import React, {Component} from 'react'
import FlexView from 'react-flexview';
import SharedStyles from './SharedStyles.js'
import * as firebase from "firebase/app"

import dic from '../dictionary_compact.json'

export default class Dictonary extends Component {
  state={
    definition: []
  }

  componentDidMount = () => {
    this.getDef(this.props.word)
  }
  getDef = async (word) => {
    const firefunctions = firebase.functions()
    const def = await firefunctions.httpsCallable('dictionary')(word)
    if (def.data.definition !== null) {
      // console.log('defs', def.data.definition.includes('2.'))
      const split = def.data.definition.includes('2.') ? def.data.definition.split(/\d\.?\d*/g).filter(i => i !== '') : [def.data.definition]
      this.setState({definition: split})
    }

  }


  render() {
    // const dict = JSON.parse(dic)
    
    const {definition} = this.state
    console.log('state definition', definition)
    if (!definition) {
      return null
    }
    // const split = definition.indexOf(/\d\.?\d*/g) > -1 ? definition.split(/\d\.?\d*/g).filter(i => i !== '') : [definition]
    return (
      <FlexView column style={{...SharedStyles.paragraph, marginLeft: '8vw', marginRight: '8vw', textAlign:'left'}}>
        {definition.map((item, i) => {
          return (
            <FlexView>
              <span style={{...SharedStyles.paragraph, textAlign:'right', marginRight: '20px'}}>{i+1 + '.'}</span>
              {item}
            </FlexView>
          )
        })} 
      </FlexView>
    )
  }

}