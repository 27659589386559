import React from 'react'
import FlexView from 'react-flexview';

const styles = {
  backdrop: {
    backgroundColor: 'black',
    height: '100%',
    position: 'fixed',
    top:0,
    left:0,
    width: '100%',
    zIndex: 1,
    transform: 'translateX(-100%)',
    transition: 'opacity 0.3s ease-out, visibility 0.3s ease-out'
  }
}

const sideDrawer = props => {
  const translateX = props.open ? 'translateX(0)' : 'translateX(-100%)'
  const opacity = props.open ? '60%' : '0%'
  const visibility = props.open ? 'visible' : 'hidden'
  return (
    <FlexView
      vAlignContent='center'
      style={{...styles.backdrop, opacity, visibility, transform: translateX}}
      onClick={props.toggleDrawer}/>
  )
}

export default sideDrawer;