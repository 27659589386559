import React from 'react'
import FlexView from 'react-flexview';
import SharedStyles from './SharedStyles.js'

const styles = {
  block: {
    backgroundColor: '#242235',
    margin: '2vw',
    marginLeft:'5vw',
    marginRight:'5vw',
    textAlign: 'center',
    border: '2px solid transparent',
    borderRadius: '8px',
    paddingLeft: '5vw',
    paddingRight: '5vw',
    paddingTop: '2vw',
    paddingBottom: '1vw',
  }
};

const ParagraphBlock = props => {
  const isMobile = props.screenSize.width < 640
  const inverted = props.inverted ? true : false
  console.log('inverted', inverted)
  const backgroundColor = inverted ? '#F8F9FD' : styles.block.backgroundColor
  const color = inverted ? SharedStyles.colors.black : 'white'
  const colorh = inverted ?  SharedStyles.colors.blue :  SharedStyles.colors.red

  return (
    <FlexView style={{...styles.block, backgroundColor}} column vAlignContent='center' hAlignContent='center'>
      <p style={{...SharedStyles.paragraph, color}}>{props.body}</p>
      <h3 style={{...SharedStyles.h3, color:colorh}}>{props.heading}</h3>
    </FlexView>
  )
}

export default ParagraphBlock;
