import React, {Component} from 'react'
import FlexView from 'react-flexview';
import Navigation from './components/Navigation';
import Searchbar from './components/Searchbar';
import * as firebase from "firebase/app"
import "firebase/database";
import MetaTags from './MetaTags'
// import { useHistory } from "react-router-dom";


//Content

import SearchResults from './components/SearchResults';

import ParagraphBlock from './components/ParagraphBlock';
import SummaryBlocks from './components/SummaryBlocks';
import HighlightBlock from './components/HighlightBlock';
import Heading from './components/Heading';
import Paragraph from './components/Paragraph';
import WordLists from './components/WordLists';
import Footer from './components/Footer';
import footerContent from './content/footer.json'
import Links from './components/Links';
import Dictionary from './components/Dictionary';
import Image from './components/Image';


const database = require('./database.json');



const numberArray = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen']
const baseWordLimit = 600

export default class PageGenerator extends Component {
  state={
    search: {
      word: '',
      startsWith: '',
      contains: '',
      endsWith: '',
      length: 0
    },
    loading: false,
    content: {},
    words: [],
    resultsCount: null,
    showMoreResultsButton: false
  }

  handleSearchSubmit = (search) => {
    console.log('handling SearchSubmit')
    // return
    this.setState({search})
    const {word, startsWith, contains, endsWith, length} = search
    const newContains = contains.indexOf('&') > 0 ? contains.split('&').join('/and/') : contains
    console.log('newContains', newContains)
    let url = ''
    if (length > 1) {
      const numText = numberArray[length - 1]
      if (startsWith !== '') {
        url = `${numText}-letter-words-starting-with/${startsWith}/unscramble/${word}/words-with/${newContains}/words-end-in/${endsWith}`
      } else if (endsWith !== '') {
        url = `${numText}-letter-words-ending-in/${endsWith}/unscramble/${word}/words-with/${newContains}/`
      } else if (contains !== '') {
        url = `${numText}-letter-words-with/${newContains}/unscramble/${word}/`
      } else {
        url = `unscramble/${word}/length/${length}`
      }
    } else {
      url = `unscramble/${word}/words-start-with/${startsWith}/words-with/${newContains}/words-end-in/${endsWith}`
    }
    var splitUrl = url.split('/')
    console.log('splitUrl', splitUrl)
    splitUrl.map((item, i) => {
      if (item === "") {
        splitUrl[i - 1] = ""
      }
    })
    const cleanedUrl = splitUrl.filter(item => item !== "").join('/').toLowerCase()
    console.log('cleanedUrl', cleanedUrl)
    // return
    // let history = useHistory();
    this.props.history.push(`/${cleanedUrl}`)
    this.unscrambleWord(word, startsWith, contains, endsWith, length, baseWordLimit)
  }

  unscrambleWord = async (word, startsWith, contains, endsWith, length, limit) => {
    this.setState({loading: true})
    console.log('unscrambling', word, startsWith, contains, endsWith)
    const firefunctions = firebase.functions()
    // console.log('firefunctions', firefunctions)
    const message = {word, startsWith, contains, endsWith, length, limit};
    const wordsArray = await firefunctions.httpsCallable('unscramble')(message)
    this.setState({loading: false})
    const showMoreResultsButton = wordsArray.data.resultsCount > wordsArray.data.words.length ? true : false
    // if (wordsArray.resultsCount > wordsArray.data.words.length) {
    //   this.state.
    // }
    console.log('wordsArray', wordsArray.data.words)
    this.setState({words: wordsArray.data.words, resultsCount: wordsArray.data.resultsCount, showMoreResultsButton})
  }


  // handleSearchChange = (event, type) => {
  //   let searchState = this.state.search
  //   console.log('handleSearchChange', type, event.target.value)
  //   if (type === 'length') {
  //     searchState[type] = parseInt(event.target.value)
  //   } else {
  //     searchState[type] = event.target.value.slice(0,15)
  //   }
  //   this.setState({search:searchState})
  // }

  componentWillMount = async () => {
    //TESTING VERSION
    // const content = await Promise.resolve(this.props.content).then(snap => snap.val())

    // LIVE VERSION
    const content = database
    console.log('content CDM', database)


    this.setState({content})
    
  }


  decodeUrl = async (filters) => {
    console.log('filters', Object.values(filters))
    const filtersArray = Object.values(filters).filter(item => item !== undefined)

    let filter = {
      word: '',
      startsWith: '',
      contains: '',
      endsWith: '',
      length: 0
    }

    let i
    console.log('filtersArray len', filtersArray.length)
    for (i = 0; i < filtersArray.length; i+=2) {
      console.log('filter1', filtersArray[i])
      if (filtersArray[i].includes('unscramble')) {
        filter.word = filtersArray[i + 1].toUpperCase()
      } else if (filtersArray[i].includes('words-start-with')) {
        filter.startsWith = filtersArray[i + 1].toUpperCase()
      } else if (filtersArray[i].includes('words-with')) {
        if (filtersArray[i + 2] === 'and') {
          filter.contains = filtersArray[i + 1].toUpperCase() + '&' +  filtersArray[i + 3].toUpperCase() 
        } else {
          filter.contains = filtersArray[i + 1].toUpperCase()
        }

      } else if (filtersArray[i].includes('words-end-in')) {
        filter.endsWith = filtersArray[i + 1].toUpperCase()      
      } else if (filtersArray[i].includes('length')) {
        filter.length = parseInt(filtersArray[i + 1])
      }
    }

 
    if (filtersArray[0].includes('-letter-words')) {
      const numText = filtersArray[0].substring(0, filtersArray[0].indexOf('-'))
      const num = numberArray.indexOf(numText) + 1
      filter.length = num
      if (filtersArray[0].includes('-starting')) {
        filter.startsWith = filtersArray[1].toUpperCase()
      } else if (filtersArray[0].includes('words-with')) {
        if (filtersArray[2] === 'and') {
          filter.contains = filtersArray[1].toUpperCase() + '&' +  filtersArray[3].toUpperCase() 
        } else {
          filter.contains = filtersArray[1].toUpperCase()
        }
      } else if (filtersArray[0].includes('-ending')) {
        filter.endsWith = filtersArray[1].toUpperCase()
      }
    }
    console.log('state set that matters ', filter)
    this.setState(prevState => ({
      search: {...prevState.search, ...filter}
    }))
    // const {word, startsWith, contains, endsWith, length} = filter
    // this.unscrambleWord(word, startsWith, contains, endsWith, length)
  }

  componentDidMount = async () => {
    const {url, chars1} = this.props.match.params
    if (url && url.includes('-letter-words') && !chars1) {
      const numtext = url.substring(0, url.indexOf('-'))
      let searchState = this.state.search
      searchState['length'] = numberArray.indexOf(numtext) + 1
      await this.setState({search:searchState})
      const {word, startsWith, contains, endsWith, length} = this.state.search
      this.unscrambleWord(word, startsWith, contains, endsWith, length, baseWordLimit)
    }
    if (chars1 && url !== 'dictionary') {
      await this.decodeUrl(this.props.match.params)
      const {word, startsWith, contains, endsWith, length} = this.state.search
      this.unscrambleWord(word, startsWith, contains, endsWith, length, baseWordLimit)
    }
    // console.log('state that matters ', this.state.search)
  }



  sub = (string, char) => {
    console.log('substring', string)
    if (!string) {
      return
    }
    let returnString = string
    if (char) {
      if (char.indexOf(' and ') > -1 && string.indexOf('/') > -1) {
        const newChar = char.split(' ').join('/')
        returnString = returnString.split("#?").join(newChar);
      } else {
        returnString = returnString.split("#?").join(char.toUpperCase());
      }
    }
    if (this.state.search.length > 1) {
      returnString = returnString.split("$Number").join(numberArray[this.state.search.length - 1].toUpperCase());
    }
    if (string.indexOf('[word count]') > 1) {
      const wordsLength = this.state.resultsCount
      if (wordsLength > 10) {
        returnString = returnString.split("[word count]").join(this.state.words.length);
      } else {
        returnString = returnString.split("[word count] ").join('');
      }
    }
    if (string.indexOf('$Letters') > 0) {
      const withS = char.length > 1 ? 'Letters' : 'Letter'
      returnString = returnString.split("$Letters").join(withS);
    }
    return returnString
  }

  getComponent(id) {
    const {url, chars1, filter2, chars2} = this.props.match.params
    const newUrl = url ? url : 'home'
    
    const containsNum = numberArray.filter(item => newUrl.indexOf(item) > -1)
    const withNumber = containsNum.length > 0 ? newUrl.replace(containsNum[0], 'number') : newUrl
    const page = chars1 ? withNumber + '*' : withNumber
    const newChar = url ? url.indexOf('words-with') > -1 && filter2 === 'and' ? chars1 + ' and ' + chars2 : chars1 : chars1
    // const char = this.props.match.params.char
    // console.log('id', id)
    console.log('page', page)
    console.log('newChar', newChar)
    // console.log('char', char)
    
    // console.log('this.state.content[page][id].type', this.state.content[page])
    const content = this.state.content[page][id]
    
    console.log('content', content)
    const contentType = content.type
    switch (contentType) {
      case 'MetaData':
        console.log('Metagot', content)
        const {title, keywords, description} = content
        return ( <MetaTags metaData={{title: this.sub(title, newChar), keywords: this.sub(keywords, newChar), description: this.sub(description, newChar)}}/> )
      case 'Searchbar':
        return (
          <Searchbar
            key={id}
            heading={this.sub(content.heading, newChar)}
            subtitle={content.subtitle}
            highlightedWord={this.sub(content.highlightedWord, newChar)}
            onSubmit={this.handleSearchSubmit}
            search={this.state.search}
            screenSize={this.props.screenSize}/>
          )
      case 'ParagraphBlock':
        return (
          <ParagraphBlock
            key={id}
            heading={this.sub(content.heading, newChar)}
            body={this.sub(content.body, newChar)}
            screenSize={this.props.screenSize}
          />  
        )
      case 'SummaryBlocks':
        const noBlocks = Object.keys(content).filter(item => item.indexOf('heading') > -1)
        return (
          <SummaryBlocks
            content={noBlocks.map((item, i) => ({heading: content['heading' + (i + 1)], body: content['body' + (i + 1)]}))}
            screenSize={this.props.screenSize}
            />
        )
      case 'WordLists':
        const noLists = Object.keys(content).filter(item => item.indexOf('list') > -1)
        let formatted = []

        if (this.state.search.length > 1) {
          const form = noLists.map((item, i) => ({heading: this.sub(content['heading' + (i + 1)], newChar), list: this.sub(content['list' + (i + 1)], newChar).split(', '), links: this.sub(content['links' + (i + 1)],newChar).split(', ')}))
          formatted = form.map(item => {
            let newList = item.list
            let newLinks = item.links
            newList[this.state.search.length - 2] = 'remove'
            newLinks[this.state.search.length - 2] = 'remove'
            // console.log('newChar.spli', newChar.split(''))
            let i = 0
            for (i = 0; i < newChar.length - 2; i++) {
              newList[i] = 'remove'
              newLinks[i] = 'remove'
            }
            console.log('cnewList', newList, newLinks)
            return {heading: item.heading, list: newList.filter(t => t !== 'remove'), links: newLinks.filter(t => t !== 'remove')}
          })
        } else {
          formatted = noLists.map((item, i) => ({heading: this.sub(content['heading' + (i + 1)], newChar), list: this.sub(content['list' + (i + 1)], newChar).split(', '), links: this.sub(content['links' + (i + 1)],newChar).split(', ')}))
        }
        console.log('formatted', formatted)
        return (
          <WordLists
            content={formatted}
            screenSize={this.props.screenSize}
            searchChars={newChar}
            len={this.state.search.length - 1}
            />
        )
      case 'Links':
        const links = content.links.split(', ')
        const list = content.list.split(', ')
        console.log('links, list', links, list)
        return (
          <Links
            heading={content.heading}
            links={links}
            list={list}
            screenSize={this.props.screenSize}
            />
        )
        case 'HighlightBlock':
          return (
            <HighlightBlock
              heading={content.heading}
              body={content.body}
              screenSize={this.props.screenSize}
              inverted={content.inverted}
            />
          )
        case 'Heading':
          return (
            <Heading
              heading={this.sub(content.heading, newChar)}
              size={content.size}
              color={content.color}
              align={content.align}
            />
          )
        case 'Paragraph':
          return (
            <Paragraph
              paragraph={this.sub(content.paragraph, newChar)}
              color={content.color}
              align={content.align}
            />
          )
          case 'Dictionary':
            return (
              <Dictionary
                word={newChar}
              />
            )
          case 'Image':
            return (
              <Image
                name={content.name}
              />
            )
      default:
        return <div className="no_block_type" />
    }
  }

  generateContent = () => {
    const {url, chars1} = this.props.match.params
    const newUrl = url ? url : 'home'
    console.log('newUrl', this.props.match.params)
    const containsNum = numberArray.filter(item => newUrl.indexOf(item) > -1)
    const withNumber = containsNum.length > 0 ? newUrl.replace(containsNum[0], 'number') : newUrl
    const page = chars1 ? withNumber + '*' : withNumber


    
    console.log('containsNum', containsNum)
    // const url = this.props.match.params.url
    console.log('page', page)
    if (page === 'navigation') {
      return (
        <Heading
          heading={'Page Not Found'}
          size={'h1'}
        />
      )
    }
    if (Object.keys(this.state.content).length < 1) {
      console.log('nothing')
      return null
    }
    const pageContent = this.state.content[page]
    // console.log('this.props.match.params', this.props.match.params.url)
    // console.log('pageType', this.state.content)

        // add search results
    let contentArray = Object.keys(pageContent)
    // if (char) {
    //   contentArray.splice(2, 0, 'SearchResults')
    // }


    console.log('contentArray', contentArray)
    let allBlocks = contentArray.map(item => this.getComponent(item))
    console.log('this.state.search.length', this.state.search.length)
    if ((chars1 || this.state.search.length > 0) && url !== 'dictionary') {
      console.log('showing search results')
      allBlocks.splice(3, 0,
        <SearchResults
          resultsCount = {this.state.resultsCount}
          loading={this.state.loading}
          showButton={this.state.showMoreResultsButton}
          reSearch={() => {
            const {word, startsWith, contains, endsWith, length} = this.state.search
            this.unscrambleWord(word, startsWith, contains, endsWith, length)
          }}
          words={this.state.words}
          baseWord={this.state.search.word}
        />)
    }
    return allBlocks
  }

  render () {
    // console.log('match', this.props)
    console.log('state gen', this.state)
    return (
      <FlexView hAlignContent='center' name='parent' column={true} style={{height: '100%'}}>
        <Navigation screenSize={this.props.screenSize} content={this.state.content['navigation']}/>
        {this.generateContent()}
        <Footer
          content={this.state.content['navigation']}
          screenSize={this.props.screenSize}
          />
      </FlexView>
    );
  }
}