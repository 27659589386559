import React from 'react'
import FlexView from 'react-flexview';
import {Link} from 'react-router-dom'
import SharedStyles from './SharedStyles.js'

const styles = {
  block: {
    backgroundColor: '#F8F9FD',
    margin: '2vw',
    marginLeft:'5vw',
    marginRight:'5vw',
    textAlign: 'left',
    border: '2px solid transparent',
    borderRadius: '8px',
    paddingLeft: '5vw',
    paddingRight: '5vw',
    paddingTop: '1vw',
    paddingBottom: '1vw',
    maxWidth: SharedStyles.gContent.maxWidth
  },
  heading: {
    fontSize: 'x-large',
    fontWeight: '500',
    color: '#0E2641',
    margin:'1vw',
    marginLeft: '2vw',
    marginRight: '2vw',
    textAlign:'center'
    
  },
  body: {
    color: '#AAAAAA',
    fontSize: 'small',
    lineHeight: '185%',
    marginLeft: '2vw',
    marginRight: '2vw',
  }
};

// const sub = (string, char) => {
//   if (char) {
//     const newString = string.replace('?', char.toUpperCase())
//     return newString
//   } else {
//     return string
//   }
// }

const insertJSX = (array, search, replacement) => {
  const newJSXarray = array.map(item => {
    const splitItem = item.type ? [item] : item.split(search)
    if (splitItem.length > 1) {
      const newLength = splitItem.length + (splitItem.length - 1)
      const newArray = [...Array(newLength)].map((item, i) => {
        if (i % 2) {
        return (replacement)
        } else {
          return splitItem[i / 2]
        }
      })
      return newArray
    } else {
      return item
    }
  })
  console.log('newJSXarray', newJSXarray)
  const flatten = newJSXarray.flat()
  console.log('flatten', flatten)
  return flatten
}

const insertHeading = (array, search) => {
  console.log('with break', array)
  const newJSXarray = array.map(item => {
    console.log('heading item', item)
    console.log('typeof myVar != string', typeof myVar != 'string')
    const splitContent = typeof item != 'string' ? [item] : item.split(search)
    console.log('splitContent', splitContent)
    const body = splitContent.map((item, i) => {
      if (i % 2) {
        return (<h3 style={{...SharedStyles.h3}}>{item}</h3>)
      } else {
        return (item)
      }
    })
    return body
  })
  return newJSXarray
}

const buildPara = (string) => {
  // const halfBodyLen = string.length / 2
  // const spaceAtHalf = string.indexOf(' ', halfBodyLen)
  const splitContent = string.split('*')
  // console.log('splitContent', string)
  const body = splitContent.map((item, i) => {
    if (i % 2) {
      const text = item.replace(/ *\([^)]*\) */g, "")
      const url = item.match(/\((.*?)\)/)[1] 
      // console.log('even')
      console.log('url', url)
      // console.log('item', item.replace(/ *\([^)]*\) */g, ""))
      const whichLink = url.includes('.') ? <a href={url}>{text}</a> : <Link to={url}>{text}</Link>
      console.log('whichLink', url.includes('www'))
      return whichLink
      
    } else {
      console.log('odd')
      return (item)
    }
  })
  console.log('body', body)
  const addBreak = insertJSX(body, '/b', [<br/>, <br/>])
  const addHeadings = insertHeading(addBreak, '#h')
  // const addBreak = body.map(item => {
  //   console.log('item', item)

  //   const splitItem = item.type ? [item] : item.split("/b")
  //   console.log('splitItem', splitItem)
  //   if (splitItem.length > 1) {
  //     const newLength = splitItem.length + (splitItem.length - 1)
  //     console.log('newLength', newLength)
  //     const newArray = [...Array(newLength)].map((item, i) => {
  //       if (i % 2) {
  //       return ([<br/>, <br/>])
  //       } else {
  //         return splitItem[i / 2]
  //         // inc = inc + 1
          
  //       }
  //     })
  //     console.log('newArray', newArray)
  //     return newArray
      
  //   } else {
  //     return item
  //   }
  // })


  return addHeadings
}

const ParagraphBlock = props => {
  const halfBodyLen = props.body.length / 2
  const spaceAtHalf = props.body.indexOf(' ', halfBodyLen)
  const starAtHalf = props.body.indexOf('*', spaceAtHalf + 1)
  // console.log('props.body[starAtHalf + 1]', props.body[starAtHalf + 1])
  const isMidLink = props.body[starAtHalf + 1] === ' ' ? true : false
  // console.log('isMidLink', isMidLink)
  const spaceAtHalfFixed = isMidLink ? spaceAtHalf + (starAtHalf - spaceAtHalf) : spaceAtHalf 
  const isMobile = props.screenSize.width < 640
  const alignText = isMobile ? 'center' : 'left'
  return (
    <FlexView style={styles.block} column vAlignContent='center' hAlignContent={isMobile ? 'center' : 'left'}>
      <h2 style={SharedStyles.h2}>{props.heading}</h2>
      <FlexView style={{textAlign: alignText}} wrap={isMobile} column={false}>
        <p style={SharedStyles.paragraph}>
          {buildPara(props.body)}
        </p>
        {/* <p style={styles.body}>
          {buildPara(props.body.substring(0, spaceAtHalfFixed - 1 ))}
        </p> */}
        {/* <p style={styles.body}>
          {buildPara(props.body.substring(spaceAtHalfFixed , props.body.length))}
        </p> */}
        {/* <p style={styles.body}>{props.body.substring(0, spaceAtHalf)}</p>
        <p style={styles.body}>{props.body.substring(spaceAtHalf + 1, props.body.length)}</p> */}
      </FlexView>
    </FlexView>
  )
}

export default ParagraphBlock;
