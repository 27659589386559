import React, {Component} from 'react'
import FlexView from 'react-flexview';


import Navigation from './components/Navigation';
import Searchbar from './components/Searchbar';
import Footer from './components/Footer';
import footerContent from './content/footer.json'
import ParagraphBlock from './components/ParagraphBlock';
import paragraphContent from './content/paragraph.json'
import Links from './components/Links';
import linksContent from './content/links.json'
import WordLists from './components/WordLists';
import wordlistContent from './content/wordLists.json'

export default class WordsStartWith extends Component {

  state={
    search: '',
  }

  handleChange = (event) => {
    this.setState({search: event.target.value})
  }

  handleSubmit = (event) => {
    // console.log(this.props.match.params.chars)
    event.preventDefault();
    this.props.history.push(`/words-start-with/${this.state.search}`)
  }

  render() {
    console.log('this.props.location', this.props.location.pathname)
    return (
      <FlexView name='parent' column={true} style={{height: '100%'}}>
        <Navigation screenSize={this.props.screenSize}/>
        <Searchbar
          heading={'Unscramble Any Word'}
          subtitle={'The fastest way to unscramble words for Scrabble, Words with Friends etc.'}
          highlightedWord={'Unscramble'}
          onSubmit={this.handleSubmit}
          value={this.state.search}
          onChange={this.handleChange}
          onClick={() => console.log('clicked')}/>
        <ParagraphBlock
          heading={'Words that Start with the Letter '.concat(this.props.match.params.chars)}
          body={paragraphContent.content.body}
          screenSize={this.props.screenSize}
        />
        <Links
          heading={linksContent.content.heading}
          links={linksContent.content.links}
          columns={3}
          screenSize={this.props.screenSize}
          />
        <WordLists
          content={wordlistContent.content}
          screenSize={this.props.screenSize}
          />
        <Footer
          url={this.props.location.pathname}
          content={footerContent.content}
          screenSize={this.props.screenSize}
        />
      </FlexView>
    )
  }

}