import React, {Component} from 'react'
import FlexView from 'react-flexview';

import SideDrawer from './SideDrawer';
import Toolbar from './Toolbar';

export default class Navigation extends Component {
  state={
    drawerOpen: false,
  }

  drawerClickHandler = () => {
    this.setState(prevState => ({drawerOpen:!prevState.drawerOpen}))
  }

  render() {
    return (
      <FlexView column>
        <Toolbar screenSize={this.props.screenSize} toggleDrawer={this.drawerClickHandler} content={this.props.content}/>
        <SideDrawer open={this.state.drawerOpen} toggleDrawer={this.drawerClickHandler} content={this.props.content}/>
      </FlexView>
    )
  }

}